import React from 'react'
import styled from 'styled-components'
import HeroSection from '../sections/gallery/HeroSection'
import GallerySection from '../sections/gallery/GallerySection'
import Layout from '../components/layout/Layout'

const Gallery = () => {

  return (
    <Layout pageName="gallery">
      <Wrapper>
        <HeroSection />
        <GallerySection />
      </Wrapper>
    </Layout>
  )
}

export default Gallery

const Wrapper = styled.div``