import { gsap } from 'gsap'

const showContainer = (element) => {
  gsap.to(element.current, {duration: .2, autoAlpha: 1, delay: .15})
}

const logoAnimation = (element) => {
  gsap.fromTo(element.current, { opacity: 0, y: 7.5 }, { duration: 3, opacity: 1, y: 0, delay: 1 })
}

export { showContainer, logoAnimation }
