import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { breakpoint } from '../../configs/utilities'
import BookNowComponent from './BookNowComponent'
import { PageTitle, HeroDescription } from '../common/typography/Typography'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { showContainer } from '../../configs/animations'

const HeroComponent = ({ intl, ...props }) => {
  const [playAnimation, setPlayAnimation] = useState(false)
  const containerContent = useRef(null)

  useEffect(() => {
    const onPageLoad = () => {
      setPlayAnimation(true)
    }

    if (document.readyState === 'complete') {
      onPageLoad()
    } else {
      window.addEventListener('load', onPageLoad)
      return () => window.removeEventListener('load', onPageLoad)
    }
    if (playAnimation) {
      showContainer(containerContent)
    }
  }, [playAnimation])
  return (
    <AnimationWrapper ref={containerContent}>
      <Wrapper heroSm={props.heroSm} heroMd={props.heroMd} heroXl={props.heroXl}>
        <BlurredTop />
        <TextWrapper>
          <HeroDescription>{props.sectionTitle}</HeroDescription>
          <PageTitle>{props.pageTitle}</PageTitle>
        </TextWrapper>
        <BookNowWrapper>
          <BookNowComponent />
        </BookNowWrapper>
        <BlurredBottom />
      </Wrapper>
    </AnimationWrapper>
  )
}

export default injectIntl(HeroComponent)

const AnimationWrapper = styled.div`
  visibility: hidden;
`

const Wrapper = styled.div`
  background-image: url(${(props) => props.heroSm});
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 8px;

  @media screen and (min-width: ${breakpoint.md}) {
    background-image: url(${(props) => props.heroMd});
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    background-image: url(${(props) => props.heroXl});
  }
  @media screen and (min-width: ${breakpoint.huge}) {
    min-height: auto;
  }
`

const TextWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 40px;
  @media screen and (min-width: ${breakpoint.md}) {
  }
  @media screen and (min-width: ${breakpoint.xl}) {
    max-width: 1000px;
  }
  @media screen and (min-width: ${breakpoint.huge}) {
    margin-top: 150px;
    row-gap: 100px;
  }
`

const BookNowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;

  @media screen and (min-width: ${breakpoint.md}) {
    margin-top: 200px;
  }

  @media screen and (min-width: ${breakpoint.xxl}) {
    margin-top: 200px;
  }
  @media screen and (min-width: ${breakpoint.huge}) {
    margin: 300px 0;
  }
`

const BlurredTop = styled.div`
  height: 2.5vh;
  width: 100%;
  background: linear-gradient(180deg, rgba(26, 29, 34, 1) 0%, rgba(26, 29, 34, 0.25) 80%, rgba(26, 29, 34, 0) 100%);
`

const BlurredBottom = styled.div`
  height: 2.5vh;
  width: 100%;
  background: linear-gradient(0deg, rgba(26, 29, 34, 1) 0%, rgba(26, 29, 34, 0.15) 80%, rgba(26, 29, 34, 0) 100%);
`
