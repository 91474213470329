import React from 'react'
import styled from 'styled-components'
import { color, breakpoint, transition } from '../../../configs/utilities'
import { OpenModalContext } from '../../layout/Layout'

const ButtonContact = (props) => {
  return (
    <OpenModalContext.Consumer>
      {(value) => {
        return (
          <ButtonWrapper>
            <ContactButton
              onClick={() => {
                value.openContact()
              }}
              aria-hidden="true"
              className="contact"
            >
              {props.buttonText}
            </ContactButton>
          </ButtonWrapper>
        )
      }}
    </OpenModalContext.Consumer>
  )
}

export default ButtonContact

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const ContactButton = styled.span`
  color: ${color.white};
  background: ${color.greenButton};
  min-width: 110px;
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 1px;
  cursor: pointer;
  font-size: 14px;
  transition: ${transition.default};
  margin-top: 40px;

  &:hover {
    opacity: 0.7;
  }

  @media screen and (max-width: ${breakpoint.xsm}) {
    font-size: 5vw;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    min-width: 140px;
    padding: 12px 30px;
    font-size: 16px;
  }
`
