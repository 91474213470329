import React from 'react'
import { SectionWrapper } from '../../components/common/wrappers/Wrappers'
import styled from 'styled-components'
import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import { breakpoint } from '../../configs/utilities'
import { useStaticQuery, graphql } from 'gatsby'

const GallerySection = () => {
  const data = useStaticQuery(graphql`
    query {
      allGalleryJson {
        edges {
          node {
            original {
              publicURL
            }
            thumbnail {
              publicURL
            }
            vertical
          }
        }
      }
    }
  `)

  const galleryData = data.allGalleryJson.edges

  return (
    <SectionWrapper>
      <Wrapper>
        <Gallery>
          <ItemsWrapper>
            {galleryData.map((item, index) => {
              return (
                <Item
                  key={index}
                  original={item.node.original.publicURL}
                  thumbnail={item.node.thumbnail.publicURL}
                  width={'2400'}
                  height={item.node.vertical ? '3536' : '1768'}
                >
                  {({ ref, open }) => (
                    <img ref={ref} onClick={open} src={item.node.original.publicURL} alt="galleryImage" />
                  )}
                </Item>
              )
            })}
          </ItemsWrapper>
        </Gallery>
      </Wrapper>
    </SectionWrapper>
  )
}

export default GallerySection

const Wrapper = styled.div``

const ItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
  row-gap: 12px;

  @media screen and (max-width: ${breakpoint.xsm}) {
    grid-template-columns: 1fr;
    column-gap: 0;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 15px;
    row-gap: 15px;
  }
  @media screen and (min-width: ${breakpoint.xl}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 18px;
    row-gap: 18px;
  }

  /* smallItemStyles */
  img {
    object-fit: cover;
    cursor: pointer;
    width: 100%;
    height: 25vh;
    border-radius: 8px;
  }
`
