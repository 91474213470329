import React from 'react'
import styled from 'styled-components'
import { breakpoint } from '../../configs/utilities'
import { injectIntl } from 'gatsby-plugin-react-intl'
import ButtonContact from '../common/buttons/ButtonContact'

const BookNowComponent = ({ intl }) => {
  return (
    <Wrapper>
      <TextWrapper>
        <p>{intl.formatMessage({ id: 'home.hero.rent' })}</p>
        <span>{intl.formatMessage({ id: 'home.hero.gift' })}</span>
      </TextWrapper>
      <ButtonContact buttonText={intl.formatMessage({ id: 'buttons.bookNow' })} />
    </Wrapper>
  )
}

export default injectIntl(BookNowComponent)

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
  background: rgba(175, 171, 169, 0.6);
  border-radius: 8px;
  padding: 20px 50px;
  width: 80%;
  max-width: 850px;
  margin-top: 30px;

  @media screen and (min-width: ${breakpoint.md}) {
    flex-direction: row;
    column-gap: 50px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    @media screen and (min-width: ${breakpoint.md}) {
      text-align: left;
      font-size: 20px;
    }
    @media screen and (min-width: ${breakpoint.xl}) {
      font-size: 26px;
    }
    @media screen and (min-width: ${breakpoint.xxl}) {
      font-size: 32px;
    }
  }

  span {
    width: 100%;
    font-size: 14px;
    text-align: center;
    opacity: 0.7;
    @media screen and (min-width: ${breakpoint.md}) {
      text-align: left;
      font-size: 15px;
    }
    @media screen and (min-width: ${breakpoint.xl}) {
      font-size: 16px;
    }
  }
`
